import React from "react";
import WidgetButton from "./components/WidgetButton/WidgetButton";

function App() {
  return (
    // className="appContainer"
    <div style={{position:'fixed', bottom:'20px', right:'30px'}} >
      <WidgetButton />
    </div>
  );
}

export default App;
